import { css } from "../../styled-system/css";
import { splitCssProps } from "../../styled-system/jsx";
import type { HTMLStyledProps } from "../../styled-system/types";

/**
 * アラートメッセージ
 */
export const Alert = (props: HTMLStyledProps<"div">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;

  return (
    <div
      className={css(
        {
          bg: "red.50",
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "red.600",
          color: "red.600",
          px: 4,
          py: 2,
        },
        styleProps,
        cssProp,
      )}
      {...restProps}
    />
  );
};

export const Warning = (props: HTMLStyledProps<"div">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;

  return (
    <div
      className={css(
        {
          bg: "yellow.50",
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "yellow.600",
          color: "yellow.600",
          px: 4,
          py: 2,
        },
        styleProps,
        cssProp,
      )}
      {...restProps}
    />
  );
};

export const Info = (props: HTMLStyledProps<"div">) => {
  const [cssProps, restProps] = splitCssProps(props);
  const { css: cssProp, ...styleProps } = cssProps;

  return (
    <div
      className={css(
        {
          bg: "blue.50",
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "blue.600",
          color: "blue.600",
          px: 4,
          py: 2,
        },
        styleProps,
        cssProp,
      )}
      {...restProps}
    />
  );
};
